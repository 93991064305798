@import '../../scss/mixins';

.metablobs {
  background-color: #fff;

  &__container {
    @include container;
    max-width: 100rem;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1120px) {
      flex-direction: column-reverse;
      align-items: center;
      padding-top: 4rem;
    }
    @media screen and (max-width: 992px) {
      padding: 0;
    }
  }

  &__info {
    width: 45%;
    display: flex;
    align-items: center;
    div {
      width: 70%;
      margin: 0 auto;
    }
    @media screen and (max-width: 1120px) {
      width: 100%;
      padding: 4.5rem 2rem;
      div {
        width: 100%;
      }
    }
    @media screen and (max-width: 992px) {
      padding: 4.5rem 1.5rem;
    }
  }

  &__img {
    width: 55%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
}