@import 'mixins.scss';

h1 {
  @include brandon-italic;
  margin-top: 0;
  font-size: 2.25rem;
  text-transform: uppercase;
  letter-spacing: .75rem;
}

h2 {
  @include brandon;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: .25rem;
  text-transform: uppercase;
  text-align: center;
}

p {
  @include brandon-italic;
  font-size: 1.375rem;
}

h1, p {
  &.black {
    color: #000;
  }
}

hr {
  width: 5.25rem;
  margin: 0;
  color: #fff;
  &.black {
    border-width: 2px;
    color: #000;
  }
}