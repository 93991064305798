@mixin brandon-italic {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin brandon {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
}

@mixin container {
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
}