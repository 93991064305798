@import '../../scss/mixins';

.hero {
  &__container {
    @include container;
    height: 95vh;
    min-height: 44rem;
    padding: 6vh 4rem;

    @media screen and (max-width: 1120px) {
      height: auto;
    }
    @media screen and (max-width: 540px) {
      padding: 6vh 1.5rem 12vh;
    }
  }

  &__logo {
    width: auto;
    height: 4.5rem;
    display: block;
    margin: 0 auto 3rem;
    @media screen and (max-width: 992px) {
      height: 2.65rem;
    }
  }

  &__flex {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;
    @media screen and (max-width: 1120px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__video {
    video {
      width: auto;
      height: calc(82vh - 7.5rem);

      @media screen and (max-width: 992px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__info {
    max-width: 31.5rem;
    padding: 1rem 0 0 1rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      margin-bottom: 1rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }
    p:nth-child(5) {
      margin-top: 0;
    }
    @media screen and (max-width: 992px) {
      max-width: 100%;
      padding: 0;
      img {
        display: none;
      }
      p {
        font-size: 1.375rem;
      }
    }
  }
}