body, html {
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

h1 {
  text-transform: uppercase;
  letter-spacing: .75rem;
  margin-top: 0;
  font-family: brandon-grotesque, sans-serif;
  font-size: 2.25rem;
  font-style: italic;
  font-weight: 400;
}

h2 {
  letter-spacing: .25rem;
  text-transform: uppercase;
  text-align: center;
  font-family: brandon-grotesque, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
}

p {
  font-family: brandon-grotesque, sans-serif;
  font-size: 1.375rem;
  font-style: italic;
  font-weight: 400;
}

h1.black, p.black {
  color: #000;
}

hr {
  width: 5.25rem;
  color: #fff;
  margin: 0;
}

hr.black {
  color: #000;
  border-width: 2px;
}

.hero__container {
  width: 100%;
  max-width: 90rem;
  height: 95vh;
  min-height: 44rem;
  margin: 0 auto;
  padding: 6vh 4rem;
}

@media screen and (max-width: 1120px) {
  .hero__container {
    height: auto;
  }
}

@media screen and (max-width: 540px) {
  .hero__container {
    padding: 6vh 1.5rem 12vh;
  }
}

.hero__logo {
  width: auto;
  height: 4.5rem;
  margin: 0 auto 3rem;
  display: block;
}

@media screen and (max-width: 992px) {
  .hero__logo {
    height: 2.65rem;
  }
}

.hero__flex {
  width: 100%;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

@media screen and (max-width: 1120px) {
  .hero__flex {
    flex-direction: column;
    align-items: center;
  }
}

.hero__video video {
  width: auto;
  height: calc(82vh - 7.5rem);
}

@media screen and (max-width: 992px) {
  .hero__video video {
    width: 100%;
    height: auto;
  }
}

.hero__info {
  max-width: 31.5rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 0 1rem;
  display: flex;
  position: relative;
}

.hero__info h1 {
  margin-bottom: 1rem;
}

.hero__info img {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__info p:nth-child(5) {
  margin-top: 0;
}

@media screen and (max-width: 992px) {
  .hero__info {
    max-width: 100%;
    padding: 0;
  }

  .hero__info img {
    display: none;
  }

  .hero__info p {
    font-size: 1.375rem;
  }
}

form {
  flex-direction: column;
  display: flex;
}

form input {
  width: 27rem;
  height: 5rem;
  color: #fff;
  background-color: #0000;
  border: 1px solid #fff;
  outline: none;
  margin-bottom: 1.25rem;
  padding-left: 1rem;
  font-family: brandon-grotesque, sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
}

form input::placeholder {
  color: #fff;
  opacity: .9;
}

form button {
  width: 27rem;
  height: 5rem;
  color: #000;
  letter-spacing: .25rem;
  cursor: pointer;
  background-color: #fff;
  border: none;
  font-family: brandon-grotesque, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  transition: background-color .35s;
}

form button:hover {
  color: #fff;
  background-color: #212121;
}

@media screen and (max-width: 540px) {
  form, form input, form button {
    width: 100%;
  }
}

.metablobs {
  background-color: #fff;
}

.metablobs__container {
  width: 100%;
  max-width: 90rem;
  max-width: 100rem;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

@media screen and (max-width: 1120px) {
  .metablobs__container {
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 4rem;
  }
}

@media screen and (max-width: 992px) {
  .metablobs__container {
    padding: 0;
  }
}

.metablobs__info {
  width: 45%;
  align-items: center;
  display: flex;
}

.metablobs__info div {
  width: 70%;
  margin: 0 auto;
}

@media screen and (max-width: 1120px) {
  .metablobs__info {
    width: 100%;
    padding: 4.5rem 2rem;
  }

  .metablobs__info div {
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .metablobs__info {
    padding: 4.5rem 1.5rem;
  }
}

.metablobs__img {
  width: 55%;
  height: 100%;
}

.metablobs__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

@media screen and (max-width: 992px) {
  .metablobs__img {
    width: 100%;
  }
}

.about__container {
  width: 100%;
  max-width: 90rem;
  align-items: center;
  gap: 5rem;
  margin: 0 auto;
  padding: 6rem 9.5rem;
  display: flex;
}

@media screen and (max-width: 992px) {
  .about__container {
    padding: 3rem 4rem;
  }
}

@media screen and (max-width: 820px) {
  .about__container {
    flex-direction: column;
    gap: 3.5rem;
    padding: 4.5rem 3.5rem;
  }
}

.about__img {
  width: 13.5rem;
  height: 13.5rem;
}

@media screen and (max-width: 540px) {
  .about__info h1, .about__info p {
    text-align: center;
  }

  .about__info hr {
    margin: auto;
  }
}

.footer {
  background-color: #212121;
}

.footer__container {
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: 2.5rem;
  display: flex;
}

.footer__container form {
  flex-direction: row;
  gap: 1rem;
}

.footer__container form input, .footer__container form button {
  margin-bottom: 0;
}

@media screen and (max-width: 1120px) {
  .footer__container {
    flex-direction: column;
  }
}

@media screen and (max-width: 992px) {
  .footer__container {
    padding: 2.5rem 1.5rem;
  }

  .footer__container form {
    flex-direction: column;
  }
}

.footer__socials {
  border-top: 1px solid #3e3e3e;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem;
  display: flex;
}

.footer__socials img {
  cursor: pointer;
  transition: transform .35s;
}

.footer__socials img:hover {
  transform: scale(1.1);
}

/*# sourceMappingURL=index.66675246.css.map */
