@import '../../scss/mixins';

.about {
  &__container {
    @include container;
    padding: 6rem 9.5rem;
    display: flex;
    align-items: center;
    gap: 5rem;
    @media screen and (max-width: 992px){
      padding: 3rem 4rem;
    }
    @media screen and (max-width: 820px){
      flex-direction: column;
      gap: 3.5rem;
      padding: 4.5rem 3.5rem;
    }
  }

  &__img {
    width: 13.5rem;
    height: 13.5rem;
  }

  &__info {
    @media screen and (max-width: 540px){
      h1, p {
        text-align: center;
      }
      hr {
        margin: auto;
      }
    }
  }
}