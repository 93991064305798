body, html {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
}

html {
  font-size: 16px;
}

*{
  box-sizing: border-box;
}

@import 'scss/text', 'scss/mixins';