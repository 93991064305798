@import '../../scss/mixins';

form {
  display: flex;
  flex-direction: column;
  input {
    width: 27rem;
    height: 5rem;
    padding-left: 1rem;
    margin-bottom: 1.25rem;
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    font-size: 1.25rem;
    @include brandon-italic;
    outline: none;
    &::placeholder {
      color: #fff;
      opacity: 0.9;
    }
  }
  button {
    width: 27rem;
    height: 5rem;
    border: none;
    background-color: #fff;
    @include brandon;
    font-weight: 600;
    font-size: 1.5rem;
    color: #000;
    letter-spacing: .25rem;
    cursor: pointer;
    transition: background-color 350ms;

    &:hover {
      background-color: #212121;
      color: #fff;
    }
  }
  @media screen and (max-width: 540px){
    width: 100%;
    input, button {
      width: 100%;
    }
  }
}