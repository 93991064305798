.footer {
  background-color: #212121;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding: 2.5rem;

    form {
      flex-direction: row;
      gap: 1rem;
      input, button {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 1120px) {
      flex-direction: column;
    }
    @media screen and (max-width: 992px) {
      padding: 2.5rem 1.5rem;
      form {
        flex-direction: column;
      }
    }
  }

  &__socials {
    padding: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    border-top: 1px solid #3E3E3E;

    img {
      cursor: pointer;
      transition: transform 350ms;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}